<script setup lang="ts">
const { id } = defineProps<{
  id: number
}>()

const { data, status } = useLazyFetch(`/api/posts/${id}`, {
  pick: ['slug', 'featured_media', 'title', 'date', 'categories', 'excerpt'],
})
</script>

<template>
  <article
    v-if="status === 'pending'"
    class="h-[264px] rounded bg-gray-900 ring-1 ring-gray-400"
  >
    <header class="relative flex flex-col h-48">
      <div class="grow flex">
        <USkeleton
          class="w-full h-full bg-gradient-to-b from-gray-400 to-gray-900 rounded"
        />
        <div class="absolute inset-0 flex items-center justify-center">
          <UIcon
            name="hugeicons:image-01"
            class="text-gray-100 size-12 animate-pulse"
          />
        </div>
      </div>
      <USkeleton
        class="w-80 h-4 bg-gray-500 mx-4"
      />
    </header>
    <footer class="p-4 flex flex-col gap-4">
      <div class="flex justify-between">
        <USkeleton
          class="w-20 h-4 bg-gray-600"
        />
        <USkeleton
          class="w-40 h-4 bg-gray-600"
        />
      </div>
      <USkeleton
        class="w-90 h-3 bg-gray-400"
      />
    </footer>
  </article>
  <article
    v-else
    class="shadow group ring-1 ring-gray-800 rounded h-[264px]"
  >
    <header class="relative h-48">
      <NuxtLink :to="{ name: 'uri', params: { uri: data.slug } }">
        <div class="absolute inset-0 bg-gradient-to-b from-transparent to-gray-900 group-hover:to-gray-950 transition-all" />

        <AppImage
          :id="data.featured_media"
          fit="cover"
          :width="375"
          :height="192"
          class="h-full w-full object-cover rounded-t"
        />
        <div

          class="absolute bottom-0 px-4 text-gray-100 text-sm font-semibold group-hover:text-primary transition-colors"
        >
          <!-- eslint-disable vue/no-v-html -->
          <h2
            v-html="data.title.rendered"
          />
        </div>
      </NuxtLink>
    </header>
    <footer class="bg-gray-900 group-hover:bg-gray-950 transition-all flex flex-col gap-2 font-mono text-xs slashed-zero p-4 rounded-b">
      <div class="flex justify-between ">
        <section class="inline-flex items-center gap-2">
          <UIcon
            name="hugeicons:calendar-02"
            class="size-4 text-primary"
          />
          <time
            class="font-medium text-gray-300"
            :datetime="data.date"
          >{{ data.date?.slice(0, 10) }}</time>
        </section>
        <section>
          <CleanCardCategory
            :id="data.categories[0]"
          />
        </section>
      </div>
      <div>
        <span
          class="line-clamp-1 text-gray-300 text-xs tracking-tighter"
          v-html="data.excerpt.rendered"
        />
      </div>
    </footer>
  </article>
</template>
