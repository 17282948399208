<script setup lang="ts">
const { id } = defineProps<{
  id: number
}>()

const { data } = await useFetch(`/api/categories/${id}`, {
  pick: ['name', 'slug'],
})
</script>

<template>
  <NuxtLink
    :to="`/kategoria/${data.slug}`"
    class="font-medium text-gray-100 hover:text-primary transition-colors"
  >
    {{ data.name }}
  </NuxtLink>
</template>
